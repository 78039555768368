import React, { useEffect, useState, useRef } from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    TypingIndicator,
    Avatar,
} from '@chatscope/chat-ui-kit-react';
import '../style/model.css';
import { useChatContext } from '../../contexts/chatContext';
import { useConversationContext, ConversationProvider } from '../../contexts/conversationContext';
import { useUser } from '../../contexts/dataUserContext';
import avatar from '../../media/memoji.png';
import arrowDown from '../../media/chevron-top-small.svg';
import Document from '../../media/document.svg';
import MartaAvatar from '../../media/martaavatar.svg';
import Spinner from '../parts/LoadingSpinnerTranscribe'; // Import Spinner component

const Model = () => {
    const { messages, isTyping, handleSend, handleCopyToClipboard, notificationVisible, latestTranscript } = useConversationContext();
    const { conversationDemo, setConversationDemo } = useChatContext();
    const { userName, userID } = useUser();
    const [isArrowDown, setIsArrowDown] = useState(false);
    const [transcript, setTranscript] = useState(null);
    const [transcriptHeight, setTranscriptHeight] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const textRef = useRef(null);
    
    useEffect(() => {
        // Update the component with the latest transcript data
        if (latestTranscript) {
            setTranscript(latestTranscript);
            handleSend(conversationDemo + latestTranscript);
            setConversationDemo(latestTranscript);
            setIsLoading(false);
        }
    }, [latestTranscript]);

    useEffect(() => {
        setIsArrowDown(false);
        // eslint-disable-next-line
    }, [window.location.href]);

    useEffect(() => {
        // Get the height of the transcript element
        if (isArrowDown && textRef.current) {
            setTranscriptHeight(textRef.current.clientHeight);
        }
        else {
            // Scroll up smoothly when isArrowDown is false
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

    }, [isArrowDown, transcript]);

    const TranscriptAvatar = () => {
        return (
            <div className='flex flex-col mb-5'>
                <div className="flex-col items-center">
                    <div className="flex">
                        <img className="w-5 h-5" src={avatar} alt="" />
                        <p className="text-xs ml-2 text-darkgray ">{userName}</p>
                    </div>
                </div>
                <div className='h-10'>
                    <p className="text-xs flex items-center mt-2 ml-5">
                        <span>
                            <img src={Document} alt="" />
                        </span>
                        <button onClick={() => setIsArrowDown((prev) => !prev)} className='flex items-center'>Transcript
                            <span style={{ transform: isArrowDown ? 'rotate(0deg)' : 'rotate(180deg)' }}>
                                <img src={arrowDown} alt="" />
                            </span>
                        </button>
                    </p>
                    {isArrowDown && (
                        <div className='p-2 leading-5 text-sm' style={{ maxWidth: '80%', marginLeft: '20px', fontSize: '1rem' }} ref={textRef}>
                            {transcript ? transcript : ''}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div>
            {isLoading ? ( // Show spinner while loading
                <Spinner />
            ) : (
                <div className='h-[75vh] lg:h-[88vh] ml-1 pl-4 pr-4 lg:pl-0 lg:pr-0 w-[auto] lg:w-[640px] lg:mt-[0rem] lg:ml-[1rem] md:ml-[0rem]'>
                    <MainContainer>
                        <ChatContainer>
                            <MessageList
                                scrollBehavior="auto"
                                typingIndicator={isTyping ? <TypingIndicator content="Marta is typing..." /> : null}
                            >
                                <TranscriptAvatar />
                                {messages && messages.map((message, i) => (
                                    <Message key={i} model={message} className={`message${i}`} style={{ marginTop: i === 1 && isArrowDown ? transcriptHeight : '0px', fontSize: '1rem' }}>
                                        {message.sender === 'user' && (
                                            <Avatar style={{ width: '10px' }} src={require(`../../media/memoji.png`)} name="User" />
                                        )}

                                        {
                                            message.sender === "Marta" ?
                                                <Message.Header sender="Marta" /> :
                                                <Message.Header sender={userName} />
                                        }
                                        {message.sender === 'Marta' && (
                                            <Avatar
                                                style={{
                                                    padding: '4px',
                                                    width: '10px',
                                                    background: `linear-gradient(132.93deg, #EBBFFF 9.89%, #D24CDE 89.62%)`
                                                }}
                                                src={MartaAvatar}
                                                name="Assistant"
                                            />
                                        )}
                                        <div dangerouslySetInnerHTML={{ __html: message.message }}></div> {/* Render HTML content */}
                                        <Message.Footer
                                            onClick={() => handleCopyToClipboard(message.message)}
                                            sender="Copy"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </Message>

                                ))}

                            </MessageList>
                            <div className="flex-col items-center mb-5">
                                <div className="flex">
                                    <img className="w-5 h-5" src={avatar} alt="" />
                                    <p className="text-xs ml-2 text-darkgray ">{userName}</p>
                                </div>
                            </div>
                            <MessageInput placeholder="Ask follow-up questions..." onSend={handleSend} />
                        </ChatContainer>
                    </MainContainer>
                </div>
            )}
            {
                notificationVisible && (
                    <div style={{ position: 'fixed', bottom: '10px', right: '10px', backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white', padding: '10px', borderRadius: '5px', zIndex: '50' }}>
                        Message copied to clipboard!
                    </div>
                )
            }
        </div >
    );
};

const ModelWithContext = () => (
    <ConversationProvider>
        <Model />
    </ConversationProvider>
);

export default ModelWithContext;
