import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SidebarApp from '../components/marta/SidebarApp';
import { ChatProvider } from '../contexts/chatContext';
import { UserProvider } from '../contexts/dataUserContext'
import SoepVisits from '../components/visits/SoepVisits';
import TitleVisits from '../components/visits/TitleVisits';
import Layout from '../components/layout/Layout';
import { ChatModelProvider } from '../contexts/modelContext';
import { VisitProvider } from '../contexts/visitContext';

const Visits = () => {
    const { visitId } = useParams();

    useEffect(() => {
        // Fetch data for the specific visit using visitId
    }, [visitId]);

    return (
        <div>
            <ChatProvider>
                <UserProvider>
                    <ChatModelProvider>
                        <VisitProvider>
                            <Layout>
                                <div className='flex'>
                                    <div className='hidden lg:flex'>
                                        <SidebarApp />
                                    </div>
                                    <div className='w-full h-screen overflow-hidden'>
                                        <div className='hidden lg:flex'>
                                            <TitleVisits />
                                        </div>
                                        <div className='flex w-full items-center justify-center '>
                                            <SoepVisits />
                                        </div>
                                    </div>
                                </div>
                            </Layout>
                        </VisitProvider>
                    </ChatModelProvider>
                </UserProvider >
            </ChatProvider>
        </div >
    );

};

export default Visits;
