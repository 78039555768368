import React, { useState } from 'react';
import form from '../../media/form.svg';
import microphone from '../../media/microphone.svg';
import UploadRecording from '../marta/UploadRecording';

const Start = ({ onStartButtonClick, onStopButtonClick }) => {
    const [showStartButton, setShowStartButton] = useState(true);

    const handleUploadRecordingClick = () => {
        setShowStartButton(false);
    };

    return (
        <div className='justify-center items-center w-96'>
            <img className='m-auto' src={form} alt="form" />
            <div className='my-14'>
                <h2 className='text-center text-2xl font-semibold mb-3'>Start a new visit</h2>
                <p className='text-center text-darkgray text-sm'>Click ‘Start Visit’ and Marta will handle the transcription and note-taking for you.</p>
            </div>
            {showStartButton && (
                <button onClick={onStartButtonClick} className='text-center bg-primary hover:bg-[#af42b9] text-white font-semibold p-4 rounded-full m-auto table'>
                    <span className='hidden lg:flex items-center'>
                        <span className="mr-2"><img src={microphone} alt="" /></span>
                        Start a new visit
                    </span>
                    <span className='flex  lg:hidden items-center'>
                        <span className="mr-2"><img src={microphone} alt="" /></span>
                        Start visit
                    </span>
                </button>
            )}
            <UploadRecording onStopButtonClick={onStopButtonClick} setShowStartButton={handleUploadRecordingClick} onClick={handleUploadRecordingClick} />
        </div>
    )
}

export default Start;
