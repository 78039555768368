import React, { useState, useEffect } from 'react';
import { AudioVisualizer, LiveAudioVisualizer } from 'react-audio-visualize';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

function Wave() {
  // eslint-disable-next-line
  const [blob, setBlob] = useState(null);
  const recorder = useAudioRecorder();

  useEffect(() => {
    recorder.startRecording();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='flex justify-center m-2'>
      <div className='hidden'>
        <AudioRecorder
          recorderControls={recorder}
        />
      </div>
      <div className='hidden lg:flex my-6'>
        <div>
          {recorder.mediaRecorder && (
            <LiveAudioVisualizer
              mediaRecorder={recorder.mediaRecorder}
              height={150} // Adjusted height
              width={400} // Adjusted width
              barWidth={4}
              gap={3}
              style={{margin:'10px'}}
              barColor={'rgba(198, 73, 209, 1)'}
            />
          )}

          {blob && (
            <div className='w-screen'>
              <AudioVisualizer
                blob={blob}
                width={500} // Adjusted width
                height={50} // Adjusted height
                barWidth={15}
                gap={3}
                barColor={'rgba(198, 73, 209, 1)'}
              />
            </div>
          )}
        </div>
      </div>
      <div className='flex lg:hidden my-6'>
        <div>
          {recorder.mediaRecorder && (
            <LiveAudioVisualizer
              mediaRecorder={recorder.mediaRecorder}
              height={150} // Adjusted height
              width={350} // Adjusted width
              barWidth={4}
              gap={3}
              style={{margin:'10px'}}
              barColor={'rgba(198, 73, 209, 1)'}
            />
          )}

          {blob && (
            <div className='w-screen'>
              <AudioVisualizer
                blob={blob}
                width={350} // Adjusted width
                height={50} // Adjusted height
                barWidth={15}
                gap={3}
                barColor={'rgba(198, 73, 209, 1)'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Wave;
