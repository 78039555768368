import React, { useState, useEffect } from 'react';
import Start from '../parts/Start';
import AudioRecorder from './AudioRecorder';
import Soep from './Soep';
import TitleModel from '../marta/TitleModel';
import { ConversationProvider } from '../../contexts/conversationContext';

const Conversation = ({ newVisit, setNewVisit }) => {
  const [showComponent, setShowComponent] = useState('start');
  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const handleStartButtonClick = () => {
    setShowComponent('record');
    setIsRunning(true);
  };

  const handleStopButtonClick = () => {
    setShowComponent('soep');
    setIsRunning(false);
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60).toString().padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');

    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    let interval;

    if (isRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isRunning]);

  useEffect(() => {
    if (newVisit) {
      setNewVisit(false);
    }
  }, [newVisit, setNewVisit]);

  return (
    <div className='flex flex-col w-full justify-center h-screen'>
      {showComponent === 'soep' ? <div className='hidden lg:flex'> <TitleModel /></div> : null}
      <section className="flex w-full items-center justify-center h-full">
        {showComponent === 'soep' ? <Soep /> : null}
        {showComponent === 'record' ? (
          <AudioRecorder onStopButtonClick={handleStopButtonClick} timer={timer} formatTimer={formatTime} />
        ) : null}
        <ConversationProvider >
          {showComponent === 'start' ? (
            <Start onStopButtonClick={handleStopButtonClick} onStartButtonClick={handleStartButtonClick} />
          ) : null}
        </ConversationProvider>
      </section>
    </div>
  );
};

export default Conversation;
