import memoji from '../../media/memoji.png'

const Profile = () => {
    return(
        <>
          <img className="w-5 h-5" src={memoji} alt="Profile" />
        </>
    )
}

export default Profile
