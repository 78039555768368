import React from 'react';
import '../style/LoadingSpinner.css';

const LoadingSpinnerTranscribe = () => {
  return (
    <div className="loading-spinner flex flex-col">
      <div className="loading-circle w-8 h-8"></div>
      <h3 className='text-xl mt-3 font-semibold'>Securely transcribing your conversation</h3>
      <p className='text-xs mt-3 text-darkgray	'>This may take up to one minute. Do not close this page.</p>
    </div>
  );
};

export default LoadingSpinnerTranscribe;
