import axiosInstance from './Axios';

async function processMessageToChatGPT(chatMessages) {
    let apiMessages = chatMessages.map((messageObject) => {
        let role = '';
        if (messageObject.sender === 'ChatGPT') {
            role = 'assistant';
        } else {
            role = 'user';
        }
        return {
            role: role,
            content: messageObject.message,
        };
    });
    const localModel = localStorage.getItem('chatModel') || 'gpt-3.5-turbo-0125';

    const apiRequestBody = {
        model: localModel,
        temperature:0,
        messages: [...apiMessages],
    };

    try {
        const response = await axiosInstance.post('/chat/completions', apiRequestBody);
        return response;
    } catch (error) {
        console.error('Error processing message to ChatGPT:', error);
        throw error;
    }
}

export { processMessageToChatGPT };
