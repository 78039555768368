
const Upgrade = ({daysRemaining}) => {
    return (
        <>
            <p className="text-[#687076] text-sm font-normal">{daysRemaining} days left in your trial</p>
            <a href="#" className='bg-primary py-2 my-2 rounded text-lightgray text-sm cursor-pointer hover:bg-[#af42b9] text-center inline-block w-full' >Upgrade</a>
        </>
    )
}

export default Upgrade
