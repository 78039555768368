
import Marta from '../../media/Union.svg'

const Logo = () => {
    return (
        <>
            <span className="flex items-center mb-2">
                <img className="mr-2 w-6 h-6 background-none z-10" src={Marta} alt="Marta Logo" />
                <p className="text-black md:text-white text-xl text-center font-semibold align-middle z-10">Marta</p>
            </span>

        </>
    )
}

export default Logo