import Marta from '../components/marta/Marta'
import { UserProvider } from '../contexts/dataUserContext';

const Main = () => {
    return (
        <>
            <div className='flex'>
                <UserProvider>
                    <Marta />
                </UserProvider>
            </div>
        </>
    )
}

export default Main