import React, { useState } from 'react'; // Ensure useState is imported
import { Button, Modal } from "flowbite-react";
import 'flowbite/dist/flowbite.css';
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useParams } from 'react-router-dom';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useUser } from '../../contexts/dataUserContext';
import { logErrorToFirebase } from '../../utils/firebaseUtils';

const ModalAreYouSure = ({ openModal, setOpenModal }) => {
    const { visitId } = useParams();
    const { userID } = useUser();
    const [notificationVisible, setNotificationVisible] = useState(false);

    //delete visits
    const deleteVisit = async (visitIdToDelete) => {
        try {
            const visitRef = doc(db, `${userID}/visits/conversations`, visitIdToDelete);
            await deleteDoc(visitRef);
        } catch (error) {
            alert("Error deleting visit:", error);
            console.error("Error deleting visit:", error);
            await logErrorToFirebase(error);
        }
    };

    const handleYes = (visitId) => {
        setOpenModal(false);
        deleteVisit(visitId);
        setNotificationVisible(true);
        setTimeout(() => {
            setNotificationVisible(false);
        }, 2000);
        window.location.replace('/');
    }

    return (
        <>
            <div className="absolute z-50">
                <Modal className="bg-[#0000004D] flex justify-center items-center pt-[30vh] pb-[30vh] z-[10000000000]" show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
                    <Modal.Header />
                    <Modal.Body>
                        <div className="text-center">
                            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                Are you sure you want to delete this thread?
                            </h3>
                            <div className="flex justify-center gap-4">
                                <Button className="bg-red-500" onClick={() => handleYes(visitId)}>
                                    Yes, I'm sure
                                </Button>
                                <Button color="gray" onClick={() => setOpenModal(false)}>
                                    No, cancel
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {notificationVisible && (
                <div style={{ position: 'fixed', bottom: '10px', right: '10px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', zIndex: "100", border: "solid 1px #CBD5E1" }}>
                    <h4 style={{ fontSize: "18px", fontStyle: "normal", fontWeight: "600", lineHeight: "28px" }}>
                        Thread deleted
                    </h4>
                    <p style={{ fontSize: "14px", color: "#64748B" }}>Successfully updated.</p>
                </div>
            )}
        </>
    )
}

export default ModalAreYouSure;
