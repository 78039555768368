// TitleVisits.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useVisit } from '../../contexts/visitContext'; // Adjust the import path

const TitleVisits = () => {
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const visitId = pathSegments[pathSegments.length - 1];
    const { visitText, fetchVisitName } = useVisit(); // Use the context

    useEffect(() => {
        fetchVisitName(visitId); // Call the context function
    }, [visitId]);

    return (
        <div className='flex w-full divide-y divide-slate-200 lg:border-b-[1px] h-[40px] p-2 items-center justify-center'>
            <h1 className='text-center text-sm'>{visitText || visitId}</h1>
        </div>
    );
};

export default TitleVisits;
