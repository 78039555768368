import React, { useState, useRef, useEffect } from "react";
import Wave from '../parts/Wave';
import { db, storage } from '../../firebase';
import { useUser } from '../../contexts/dataUserContext';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, getDocs, query, orderBy, limit, doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid'; // Import uuid package

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const OPENAI_API_URL = "https://api.openai.com/v1/audio/transcriptions";
const MODEL_NAME = "whisper-1";

const AudioRecorder = ({ timer, formatTimer, onStopButtonClick }) => {
    // eslint-disable-next-line
    const [permission, setPermission] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    // eslint-disable-next-line
    const [stream, setStream] = useState(null);
    const mediaRecorderRef = useRef(null);
    const { userID } = useUser();
    const [transcriptionResult, setTranscriptionResult] = useState("");
    let uniqueID; // Declare uniqueID for the mp3 visit
    uniqueID = uuidv4();

    //get the last mp3 from firebase under visits/audios path and transcribe it
    const handleTranscription = async () => {
        try {
            const transcriptRef = collection(db, `${userID}/visits/audios`);
            const querySnapshot = await getDocs(query(transcriptRef, orderBy("timestamp", "desc"), limit(1)));

            if (!querySnapshot.empty) {
                const latestAudioDoc = querySnapshot.docs[0].data();
                const downloadURL = latestAudioDoc.downloadURL; // Assuming downloadURL is the field containing the URL

                // Call transcribeAudio function with the audio URL
                const transcriptionResult = await transcribeAudio(downloadURL);
                return transcriptionResult;
            } else {
                return ""; // Return an empty string indicating no transcription
            }
        } catch (error) {
            console.error("Error handling transcription:", error);
            return null; // Return null to indicate transcription error
        }
    };
    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    const transcribeAudio = async (audioURL) => {
        try {
            // Fetch the audio blob from the provided URL
            const audioBlob = await fetch(audioURL).then(response => response.blob());

            // Create a FormData object and append the audio blob
            const formData = new FormData();
            formData.append("file", audioBlob);

            // Include other required parameters
            formData.append("model", MODEL_NAME);
            formData.append("language", "en");

            // Send the transcription request to the Whisper API
            const response = await fetch(OPENAI_API_URL, {
                method: "POST",
                body: formData,
                headers: {
                    // No need to specify Content-Type, it will be set automatically
                    Authorization: `Bearer ${OPENAI_API_KEY}`,
                },
            });

            // Parse the transcription response
            const transcriptionResult = await response.json();
            // Parse the transcription response

            // Check if any errors match predefined errors and replace them
            const errors = [
                "Nu uitați să dați like, să lăsați un comentariu și să distribuiți acest material video pe alte rețele sociale",
                "la revedere!",
                "Nu uitați să dați like, să lăsați un comentariu și să distribuiți acest material video pe alte rețele sociale YouTube face mai vizibile materialele video care sunt urmărite până la capăt.",
                "Să ne vedem la următoarea rețetă!",
                "Să ne vedem în următoarea mea rețetă!",
                "Multumesc pentru vizionare!",
                "Mulțumim pentru vizionare!",
                "YouTube face mai vizibile materialele video care sunt urmărite până la capăt.",
                "Mulțumim pentru vizionare.",
                "Să ne vedem la următoarea mea rețetă.",
                "Nu uitați să vă abonați la canal, să vă mulțumim și",
                "Sper să ne vedem în următoarea rețetă",
                "Pa!",
                "Pa",
                "La revedere!",
                "See you later. Bye. Bye.",
                "Thank you.",
                "Thank you!",
                "La revedere",
                "Să ne vedem în următoarea rețetă.",
                "Nu uitați să vă abonați la canalul meu și să vă mulțumim pentru vizionare.",
                "Acoperiți-l și lăsați-l să se răcească până când este gata de găsit.",
                "Nu uitați să dați like, să lăsați un comentariu și să distribuiți acest material video pe alte rețele sociale."
            ];

            const replacementMessage = "The transcript seems to be null or too short.";

            // Join the errors array into a single regular expression pattern
            const errorsPattern = errors.map(error => escapeRegExp(error)).join('|');

            // Create a regular expression pattern that matches any of the errors
            const regex = new RegExp(errorsPattern, 'g');

            // Replace occurrences of errors in the text with the replacement message
            const newText = transcriptionResult.text.replace(regex, replacementMessage);

            return newText;
        } catch (error) {
            console.error("Error transcribing audio:", error);
            return null;
        }
    };

    // Save results in the transcripts folder
    const saveResultAsJson = async (result, uniqueID) => {
        try {
            // Save transcript under the user's collection
            const userTranscriptRef = collection(db, `${userID}/visits/transcripts`);
            const userTranscriptDocRef = doc(userTranscriptRef, uniqueID);
            await setDoc(userTranscriptDocRef, {
                conversation: result,
                timestamp: new Date(),
            });
            document.dispatchEvent(new Event('conversationSaved'));

            // Save transcript in the centralized transcripts collection
            const centralTranscriptRef = collection(db, `transcripts`);
            const centralTranscriptDocRef = doc(centralTranscriptRef, uniqueID);
            await setDoc(centralTranscriptDocRef, {
                conversation: result,
                timestamp: new Date(),
            });
        } catch (error) {
            console.error("Error saving conversation to Firestore:", error.message);
        }
    };


    //setup the recorder
    const setupMediaRecorder = (stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);
        const chunks = [];

        mediaRecorderRef.current.ondataavailable = (e) => {
            if (e.data.size > 0) {
                chunks.push(e.data);
            }
        };

        mediaRecorderRef.current.onstop = () => {
            const blob = new Blob(chunks, { type: "audio/mp3" });
            uploadAudioFile(blob);
        };
    };

    useEffect(() => {
        //start recording
        const startRecording = async () => {
            if ("MediaRecorder" in window) {
                try {
                    const streamData = await navigator.mediaDevices.getUserMedia({
                        audio: true,
                    });
                    setPermission(true);
                    setStream(streamData);
                    setupMediaRecorder(streamData);
                    mediaRecorderRef.current.start();
                    setIsRecording(true);
                } catch (err) {
                    alert(err.message);
                }
            } else {
                alert(
                    "The MediaRecorder API is not supported in your browser."
                );
            }
        };

        startRecording();
        

        return () => {
            if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
                mediaRecorderRef.current.stop();
                setIsRecording(false);
            }
        };
        // eslint-disable-next-line
    }, []);

    //function to upload
    const uploadAudioFile = async (blob) => {

        try {
            // Construct the reference to the storage location
            const storageRef = ref(storage, `users/${userID}/visits/audios/${uniqueID}`);

            // Upload the audio file
            await uploadBytes(storageRef, blob);

            // Get the download URL of the uploaded file
            const downloadURL = await getDownloadURL(storageRef);

            // Save the download URL and other metadata to Firestore
            await addDoc(collection(db, `${userID}/visits/audios`), {
                downloadURL,
                timestamp: new Date(),
            });

            // Perform transcription
            const transcriptionResult = await handleTranscription();
            if (transcriptionResult) {
                setTranscriptionResult(transcriptionResult);
            }
            // Save transcription result as JSON
            saveResultAsJson(transcriptionResult, uniqueID);

        } catch (error) {
            console.error("Error uploading audio file:", error.message);
        }

        return uniqueID;
    };

    const stopRecording = () => {
        onStopButtonClick();
    };

    useEffect(() => {
        if (formatTimer(timer) === "20:00") {
            stopRecording();
        }
    }, [timer, formatTimer, stopRecording]);

    return (
        <div>
            <main>
                <div className="justify-center items-center w-96">
                    <div className="my-14">
                        <h2 className="text-center text-2xl font-semibold mb-3">
                            {isRecording ? (
                                <span>Recording in progress...</span>
                            ) : (
                                <span>Stopped recording</span>
                            )}
                        </h2>
                        <p className="text-center text-darkgray text-sm">
                            Click 'Stop Recording' after your visit ends and Marta will
                            summarize it for you.
                        </p>
                    </div>
                    <div className="mt-14 w-[192px] m-auto">
                        <div className="bg-[#ECEEF0] rounded-full flex justify-center items-center px-4 py-2 font-semibold text-center">
                            <span className="flex-1 text-2xl text-center">{formatTimer(timer)}</span>
                            <span className="flex-1 text-2xl text-[#899097] text-center">/ 20:00</span>
                        </div>
                    </div>

                    <Wave />
                    <button
                        onClick={stopRecording}
                        className="text-center bg-bgred hover:bg-red-500 text-white font-semibold p-4 rounded-full m-auto table"
                    >
                        <span className="flex items-center">
                            <span className="mr-2"></span>
                            Stop recording
                        </span>
                    </button>
                </div>
            </main>
        </div>
    );
};

export default AudioRecorder;