import React, { createContext, useContext, useState, useEffect } from 'react';
import { processMessageToChatGPT } from '../services/ChatServices';
import { serverTimestamp, doc, getDoc, updateDoc, setDoc, collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../firebase';
import { useUser } from './dataUserContext';
import { generateIdWithNameAndDisease } from '../services/NameThreadService';

const Conversationcontext = createContext();

export const ConversationProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const { userID } = useUser();
  const [latestTranscript, setLatestTranscript] = useState(null);
  const [UniqueID, setUniqueID] = useState(null);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: 'incoming + user',
      sender: 'user',

    };

    const newMessages = [...messages, newMessage];
    setMessages(newMessages);
    setIsTyping(true);

    try {
      const response = await processMessageToChatGPT(newMessages);
      const assistantMessage = response.data.choices[0].message.content;

      setMessages((prevMessages) => [
        ...prevMessages,
        { message: assistantMessage, direction: 'incoming + marta', sender: 'Marta' },
      ]);

      setIsTyping(false);
    } catch (error) {
      console.error('Error processing message to ChatGPT:', error);
    }
  };

  const handleCopyToClipboard = (message) => {
    navigator.clipboard.writeText(message);
    setNotificationVisible(true);
    setTimeout(() => {
      setNotificationVisible(false);
    }, 2000);
  };

  const getLastUploadedTranscript = async () => {
    try {
      const transcriptRef = collection(db, `${userID}/visits/transcripts/`);
      const querySnapshot = await getDocs(query(transcriptRef, orderBy("timestamp", "desc"), limit(1)));
      if (!querySnapshot.empty) {
        // Get the data of the last uploaded document
        const lastUploadedDoc = querySnapshot.docs[0].data();
        const transcriptData = lastUploadedDoc.conversation;
        return transcriptData;
      } else {
        console.log("No transcripts found in the collection.");
      }
    } catch (error) {
      console.error('Error getting last uploaded transcript:', error.message);
    }
  };

  //remove
  function removeDiacritics(str) {
    // Convert non-string inputs to strings
    str = str.toString();
    // Remove diacritics
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  useEffect(() => {
    const handleEvent = () => {
      // Generează numele thread-ului unic
      async function updateTranscript() {
        const latestTranscript = await getLastUploadedTranscript();
        setLatestTranscript(latestTranscript);

        // Generate new id
        const newUniqueID = await generateIdWithNameAndDisease(latestTranscript);
        const sanitizedUniqueID = removeDiacritics(newUniqueID);
        setUniqueID(sanitizedUniqueID);
      }

      updateTranscript();
      // eslint-disable-next-line
    }

    document.addEventListener('conversationSaved', handleEvent);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('conversationSaved', handleEvent);
    };
  }, []);

  useEffect(() => {
    //adauga noile mesage in firebase
    const addMessageToFirebase = async (visitId, message) => {
      try {
        const conversationDocRef = doc(db, `${userID}/visits/conversations`, visitId);
        const conversationDoc = await getDoc(conversationDocRef);

        if (conversationDoc.exists()) {
          const updatedMessages = [...conversationDoc.data().messages, message];
          await updateDoc(conversationDocRef, {
            messages: updatedMessages,
            name: visitId,
            timestamp: serverTimestamp(),
          });
        } else {
          await setDoc(conversationDocRef, {
            messages: [message],
            name: visitId,
            timestamp: serverTimestamp()
          });
        }
      } catch (error) {
        console.error('Error updating/creating conversation document:', error);
      }
    };

    const lastMessage = messages[messages.length - 1];
    if (lastMessage && UniqueID) {
      addMessageToFirebase(UniqueID, lastMessage);
    }
  }, [messages, userID, UniqueID,]);

  return (
    <Conversationcontext.Provider
      value={{
        messages,
        isTyping,
        notificationVisible,
        handleSend,
        setIsTyping,
        latestTranscript,
        processMessageToChatGPT,
        handleCopyToClipboard,
      }}
    >
      {children}
    </Conversationcontext.Provider>
  );
};

export const useConversationContext = () => {
  const context = useContext(Conversationcontext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
};