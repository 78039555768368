import React, { useEffect, useState, createContext, useContext } from "react";
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const ChatContext = createContext();
const auth = getAuth();

const ChatProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null); // Track current user
    const storedInstruction = localStorage.getItem('initialInstruction');
    const [initialInstruction, setInitialInstruction] = useState(
        storedInstruction || "KEEP THIS IN MIND, IT IS VERY IMPORTANT: for the SOAP titles like: subjective, objective, assesment, etc add a <b> to make it bold. Also create bullet point for each idea. not lineS like a line but bullet points." +
        "You are an outstanding charting assistant The user is going to give you a transcript of a physician and patient visit. " +
        "You must carefully take the transcript and generate a comprehensive medical note for the physician. Deliver this to the physician so they can copy it to their EMR and complete their patient chart. " +
        "The patient note must adhere to the format provided below. Here are your instructions for the note: " +
        "- First, analyze the transcript. Keep in mind, the physician may also add additional information to the transcript if further detail is needed in the patient note. " +
        "- Second, format the patient note like so: " +
        "#Subjective: ##Chief Complaint: (A brief statement of the patient's reason for the visit or the primary issue they are facing. Ensure this is succinct and clear) " +
        "##History of Present Illness: (Be extremely detailed. Clearly document all symptoms, relevant history, and details about current medical symptoms, including duration, severity, and any triggering events) " +
        "(Included in the HPI, ensure all past medical conditions, surgeries, allergies, medications, and family medical history discussed during the conversation are clearly documented) " +
        "(Document the patient's current medications, dosages, and any allergies or adverse reactions to medications) " +
        "(Include information about the patient's lifestyle, such as smoking, alcohol consumption, exercise, and diet, if relevant) " +
        "(Be as comprehensive as possible, utilize all the information in the transcript in order to deliver a very detailed, gold-standard patient note) " +
        "##Review of Systems: (Document a system-by-system review of symptoms the patient is experiencing. Include all symptoms and concerns that the patient addressed during the visit. Ensure this is clearly detailed in the note as a ROS) " +
        "# Objective: ##Vital Signs: (Record the patient's vital signs, including blood pressure, heart rate, respiratory rate, temperature, and any additional measurements) " +
        "##Physical Examination Findings: (Document findings from the physical examination, including any abnormalities, tenderness, or relevant clinical signs) " +
        "##Laboratory and Diagnostic Results: (Include any relevant laboratory tests, imaging studies, or other diagnostic results, providing details of the results if available) " +
        "##Assessment of Systems: (State the assessment of various body systems, including cardiovascular, respiratory, gastrointestinal, etc., based on the conversation and physical examination. Document these clearly and list every system mentioned on the transcript. Each system should have its own line if information is included. Make it very clear for the user to read) " +
        "(Never miss key details and medical findings. Make this portion detailed) " +
        "# Assessment: ##Diagnosis: (Provide a professional analysis of the patient's medical condition and any applicable differential diagnoses based on the conversation and examination) " +
        "##Clinical Impressions: (Discuss the physician's overall impressions of the patient's health status and any concerns or areas of focus) " +
        "# Plan: Treatment Plan: (Outline the management and treatment plan discussed during the visit, including medications prescribed, therapies recommended, and any referrals to specialists or additional diagnostic tests. Extract all relevant information directly from the transcript) " +
        "Patient Education: (Include all educational information provided to the patient regarding their condition, medications, or lifestyle modifications. List patient instructions clearly, in a numbered format. Each instruction should have additional detail on what was prescribed and be on its own line) " +
        "Follow-up: (Specify the date and nature of the next follow-up appointment or any required monitoring) " +
        "# Patient Follow-up Note: (Generate a follow-up note addressed to the patient. Only include the patient name if it was given in the transcript, if not, put PATIENT NAME. The note should include: 1. A brief summary of the diagnosis or condition discussed during the visit. " +
        "2. Clear instructions for care at home, including any medications prescribed, their dosages, and how to take them. 3. Specific 'homework' for the patient - this could be exercises, dietary recommendations, symptom monitoring, or anything else relevant to their condition and recovery. " +
        "4. Discharge information, if applicable, detailing any follow-up appointments, when and how to seek further medical advice, and under what circumstances they should return to the clinic or emergency department. " +
        "5. Encouragement for the patient to follow through with the plan, emphasizing the importance of their active participation in their recovery process. " +
        "Ensure the tone is warm, supportive, and easy for the patient to understand. This note should be extremely encouraging and informative for the patient) — - Other instructions: Never repeat yourself. " +
        "Always send the note right away. Ensure your note is coherent and comprehensive, utilizing all of the information extracted from the transcript. " +
        "Do not skip any medical findings or observations from the physician. Always include all medication and prescriptions that were mentioned in the transcript and include full context, dosage, and relevant information regarding the prescription. " +
        "Use professional and appropriate medical terminology. The contents of each section should be meticulously organized and documented in the correct sections. Each section of the SOAP note should have a bolded title. " +
        "NEVER CREATE YOUR OWN TRANSCRIPT. Only use the transcript for extracting information for the note. If the user is sending you something that does not seem like patient info or a conversation, ask them if they would like to continue with the patient note. " +
        "Always do your best to create the patient note for the user given the info they send you. The user may send you a short dictation of client information. In this case, always create a patient note to the best of your abilities with the information given. " +
        "MOST IMPORTANTLY: Ensure this patient note is the 'gold standard' of patient documentation. You must make this patient note extremely accurate."
    );

    const [conversationDemo, setConversationDemo] = useState(initialInstruction);

    useEffect(() => {
        // Function to handle auth state changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user); // Update current user state
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchInitialInstruction = async () => {
            // Check if there is a logged-in user
            if (currentUser) {
                const userDocRef = doc(firestore, 'users', currentUser.uid);

                try {
                    const docSnap = await getDoc(userDocRef);

                    if (docSnap.exists()) {
                        const userData = docSnap.data();
                        setInitialInstruction(userData.initialInstruction);
                        setConversationDemo(userData.initialInstruction);
                    } else {
                        console.log("User document does not exist");
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error.message);
                }
            }
        };

        fetchInitialInstruction();
    }, [currentUser]);

    const updateInitialInstruction = async (newInstruction) => {
        const groundInstructions = "for the soap titles like: subjective, objective, assesment, etc add a <b> to make it bold. Also create bullet point for each idea.not line like a line but bullet points.";
        // Ensure that currentUser is available
        if (currentUser) {
            setInitialInstruction(groundInstructions + newInstruction);
            localStorage.setItem('initialInstruction',groundInstructions + newInstruction);

            // Update Firestore document for the user
            const userDocRef = doc(firestore, 'users', currentUser.uid);

            try {
                await setDoc(userDocRef, {  initialInstruction: groundInstructions + newInstruction }, { merge: true });
            } catch (error) {
                console.error('Error updating user data:', error.message);
            }
        }
    };

    return (
        <ChatContext.Provider value={{ initialInstruction, updateInitialInstruction, conversationDemo, setConversationDemo }}>
            {children}
        </ChatContext.Provider>
    );
};

const useChatContext = () => {
    return useContext(ChatContext);
};

export { ChatProvider, useChatContext };
