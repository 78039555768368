import Login from '../components/account/Login'

const LoginPage = () =>{
    return (
        <>
            <Login />
        </>
    )
}

export default LoginPage