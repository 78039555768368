// MobileNav.js

import React, { useState, useRef} from "react";
import SlidingPanel from 'react-sliding-side-panel';
import 'react-sliding-side-panel/lib/index.css';
import SidebarApp from "../marta/SidebarApp";
import plus from '../../media/plus-circle.svg';
import plusGray from '../../media/plusgray.svg';
import { Link, useLocation } from "react-router-dom";
import TitleVisits from "../visits/TitleVisits";
import '../style/mobileNav.css';
import TitleModel from "../marta/TitleModel";

const MobileNav = () => {
    const [openPanel, setOpenPanel] = useState(false);
    const location = useLocation();
    const backdropRef = useRef(null);

    const handleRefresh = () => {
        if (location.pathname === '/marta') {
            window.location.reload();
        }
    };

    const handleHide = () => {
        setOpenPanel(false);
        backdropRef.current.style.display = 'none';
    };

    const handleClickOutside = () => {
        handleHide();
    };

    const openMenu = () => {
        setOpenPanel(!openPanel);
        backdropRef.current.style.display = 'block';

    }

    return (
        <>
            <div onClick={() => setOpenPanel(!openPanel)} className="mobile-nav divide-y divide-slate-200 z-50 fixed border-b-[1px] bg-white w-full left-0 right-0">
                <div className=" flex lg:hidden justify-between items-center z-50">
                    <div>
                        <button onClick={openMenu}>
                            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 22H31M13 16H31M13 28H21" stroke="#889096" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                    <div>
                        {location.pathname === '/marta' ? <TitleModel /> : <TitleVisits />}
                    </div>
                    <div className="flex content-center items-center justify-items-center">
                        {location.pathname === '/marta' ?
                            <>
                                <Link to={`/marta`} onClick={handleRefresh}>
                                    <button className="flex items-center justify-center" >
                                        <img className="w-[23px] h-[23px] mr-2" src={plusGray} alt="" />
                                    </button>
                                </Link>
                            </>
                            :
                            <>
                                <Link to={`/marta`} onClick={handleRefresh}>
                                    <button className="flex items-center justify-center" >
                                        <img className="w-[25px] h-[25px] mr-2" src={plus} alt="" />
                                    </button>
                                </Link>
                            </>
                        }

                    </div>
                </div>
            </div >
            <div ref={backdropRef} onClick={handleClickOutside} className={`backdrop lg:hidden ${openPanel ? 'visible' : ''}`}></div>
            <SlidingPanel
                panelContainerClassName={SlidingPanel}
                type={'left'}
                isOpen={openPanel}
                size={100}
                >
                <SidebarApp      
                    isOpen={openPanel}
                    backdropClicked={() => setOpenPanel(!openPanel)}
                    />
            </SlidingPanel >
        </>
    );
};

export default MobileNav;
