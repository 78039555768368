import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { ConversationList, Conversation } from '@chatscope/chat-ui-kit-react';
import { Card } from "flowbite-react";


const VisitSegment = ({ title, visits, handleMouseEnter, handleMouseLeave, visibleModalId, setOpenRename, setOpenModal, visitId }) => {
    const [top, setTop] = useState('');
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        const activeElement = document.querySelector('.visit-card.active');

        if (activeElement) {
            const position = activeElement.getBoundingClientRect();
            const scrollableParent = document.querySelector('.sidebar');
            activeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest', // Adjust this as needed
            });
            if (scrollableParent) {
                const currentWidth = window.innerWidth;
                const scrollTop = scrollableParent.scrollTop;
                const adjustedTop = Math.round(position.top - scrollTop + 30);
                if (currentWidth < 1024) {
                    setMobile(true);
                    const adjustedTop = Math.round(position.top - scrollTop);
                    setTop(`${adjustedTop}px`);
                } else {
                    setTop(`${adjustedTop}px`);
                }
            }
        }
    }, [visitId]);

    return (
        <>
            {visits.length > 0 && (
                <>
                    <h2 className='text-[#889096] p-1 text-xs mb-1'>{title.toUpperCase()}</h2>
                    <div className='mb-4'>
                        {visits.map((visit, index) => (
                            <div key={visit.id || index} onMouseLeave={handleMouseLeave}>
                                <Link to={`/marta/${visit.id.replace(/ /g, '-')}`}>
                                    <ConversationList>
                                        <div>
                                            <div className={`visit-card rounded-md text-[#687076] hover:bg-[#ECEEF0] flex justify-between ${visit.id.replace(/ /g, '-') === visitId ? 'bg-[#eceded] active' : ''}`}>
                                                <Conversation
                                                    className='overflow-hidden'
                                                    info={
                                                        visit.name
                                                            ? visit.name.replace(/-/g, ' ')
                                                            : visit.id.replace(/-/g, ' ')
                                                    }
                                                />                                                <div onMouseEnter={() => handleMouseEnter(visit.id.replace(/ /g, '-'))} className={`p-1 dots ${visit.id.replace(/ /g, '-') === visitId ? 'block' : 'hidden'}`}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="24" height="24" rx="4" />
                                                        <path d="M5.875 12H5.88375M12 12H12.0087M18.125 12H18.1337M6.75 12C6.75 12.2321 6.65781 12.4546 6.49372 12.6187C6.32962 12.7828 6.10706 12.875 5.875 12.875C5.64294 12.875 5.42038 12.7828 5.25628 12.6187C5.09219 12.4546 5 12.2321 5 12C5 11.7679 5.09219 11.5454 5.25628 11.3813C5.42038 11.2172 5.64294 11.125 5.875 11.125C6.10706 11.125 6.32962 11.2172 6.49372 11.3813C6.65781 11.5454 6.75 11.7679 6.75 12ZM12.875 12C12.875 12.2321 12.7828 12.4546 12.6187 12.6187C12.4546 12.7828 12.2321 12.875 12 12.875C11.7679 12.875 11.5454 12.7828 11.3813 12.6187C11.2172 12.4546 11.125 12.2321 11.125 12C11.125 11.7679 11.2172 11.5454 11.3813 11.3813C11.5454 11.2172 11.7679 11.125 12 11.125C12.2321 11.125 12.4546 11.2172 12.6187 11.3813C12.7828 11.5454 12.875 11.7679 12.875 12ZM19 12C19 12.2321 18.9078 12.4546 18.7437 12.6187C18.5796 12.7828 18.3571 12.875 18.125 12.875C17.8929 12.875 17.6704 12.7828 17.5063 12.6187C17.3422 12.4546 17.25 12.2321 17.25 12C17.25 11.7679 17.3422 11.5454 17.5063 11.3813C17.6704 11.2172 17.8929 11.125 18.125 11.125C18.3571 11.125 18.5796 11.2172 18.7437 11.3813C18.9078 11.5454 19 11.7679 19 12Z" stroke="#889096" stroke-width="1.45833" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </ConversationList>
                                </Link>
                                {visibleModalId === visit.id.replace(/ /g, '-') && (
                                    <div style={{ top: top, zIndex: '10000000000000000000000' }} className={`thread-edit absolute z-10 rounded-md p-1 w-[189px] ${mobile ? 'fixed left-11' : 'absolute left-48 '}`}>
                                        <Card className="max-w-sm rounded-md p-1 card">
                                            <p className="font-normal rounded-md text-gray-700 hover:bg-[#ECEEF0] cursor-pointer dark:text-gray-400 mb-0 p-3" onClick={() => setOpenRename(true)}>
                                                Edit visit name
                                            </p>
                                            <p onClick={() => setOpenModal(true)} className="font-normal rounded-md cursor-pointer hover:bg-[#ECEEF0] text-red-500 dark:text-gray-400 mt-0 p-3">
                                                Delete thread
                                            </p>
                                        </Card>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    )
};

export default VisitSegment;
