// Layout.js

import React from 'react';
import MobileNav from '../parts/MobileNav';

const Layout = ({ children }) => {
    return (
        <div className='flex flex-col'>
            <MobileNav />
            {children}
        </div>
    );
};

export default Layout;