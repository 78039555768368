import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Marta from "./pages/Marta.js";
import Login from "./pages/LoginPage.js";
import NotFoundPage from "./pages/NotFoundPage";
import VisitPage from "./pages/Visits"
import { VisitProvider } from './contexts/visitContext';
import posthog from 'posthog-js';
import { PostHogProvider } from './contexts/postHugContext';

posthog.init('phc_cpKzCK2k5ciH3VsIvfnsExuwyTQC7UfR62GpXJNAO39', { api_host: 'https://eu.i.posthog.com' });

export default function App() {
  return (
    <PostHogProvider>
      <VisitProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="marta" element={<Marta />} />
            <Route path="login" element={<Login />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/marta/:visitId" element={<VisitPage />} />
          </Routes>
        </BrowserRouter>
      </VisitProvider>
    </PostHogProvider>

  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);