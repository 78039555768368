// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyCSzlSiBhlhe3ykxq1a77L10ePz7dSdAD8",
  authDomain: "marta-ai01.firebaseapp.com",
  databaseURL: "https://marta-ai01-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "marta-ai01",
  storageBucket: "marta-ai01.appspot.com",
  messagingSenderId: "1006815303758",
  appId: "1:1006815303758:web:649c7cb3695e8d7d398b1f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const firestore = getFirestore(app);
const storage = getStorage(app);
const db = getFirestore(app);


export { app, auth, firestore, storage, db };
