import React, { useEffect, useState } from "react";
import { Card } from "flowbite-react";
import Upgrade from "./Upgrade";
import { getUserSignUpDate } from '../../services/firebaseService';
import { useUser } from '../../contexts/dataUserContext';

const TrialExpired = () => {
    const [remainingDays, setDaysRemaining] = useState(7);
    const { userID } = useUser();

    useEffect(() => {
        const fetchDays = async () => {
            const remainingDays = await getUserSignUpDate(userID);
            setDaysRemaining(remainingDays);
        };
        fetchDays();
    }, [])

    return (
        <>
            <Card className="absolute w-full h-full mx-auto inset-0 top-auto bottom-auto bg-[#afabab63] z-50 flex items-center my-2 ">
                <div class="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
                    <div class="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
                        <div class="text-center mb-4">
                            <h2 class="text-xl font-bold">Your Trial Has Expired</h2>
                        </div>
                        <div class="text-center mb-6">
                            <p class="text-gray-700">Your trial period has ended. To continue using the service, please renew your subscription.</p>
                        </div>
                        <div class="text-center">
                            <Upgrade daysRemaining={remainingDays} />
                        </div>
                    </div>
                </div>

            </Card>
        </>
    )
}

export default TrialExpired 
