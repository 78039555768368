import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, updateProfile, onAuthStateChanged } from 'firebase/auth';
import 'tailwindcss/tailwind.css';
import doctorSign from '../../media/doctorsign.png';
import Logo from '../parts/Logo';
import { Link } from 'react-router-dom';

const Signup = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        checkboxChecked: false,
    });

    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    const validateForm = () => {
        const newErrors = {};

        // Validate name
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        }

        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email.trim() || !emailRegex.test(formData.email)) {
            newErrors.email = 'Valid email is required';
        }

        // Validate password
        if (formData.password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters';
        }

        // Validate checkbox
        if (!formData.checkboxChecked) {
            newErrors.checkboxChecked = 'You must accept the Privacy policy';
        }

        setErrors(newErrors);

        // Return true if there are no errors, false otherwise
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate the form
        if (!validateForm()) {
            return; // Stop form submission if there are errors
        }

        const name = e.target[0].value;
        localStorage.setItem('userName', name);
        const storedUserName = localStorage.getItem('userName');
        const email = e.target[1].value;
        const password = e.target[2].value;
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;

                // Update user profile with name
                updateProfile(user, {
                    displayName: name || storedUserName,
                })
                    .then(() => {
                        // Profile updated successfully
                        console.log('User profile updated successfully');
                    })
                    .catch((profileError) => {
                        console.error('Error updating user profile:', profileError.message);
                    });

                // Set success message or redirect to another page
                setSuccessMessage('Account created successfully!');

            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                if (errorCode === 'auth/email-already-in-use') {
                    setErrors({ email: 'The email address is already in use by another account.' });
                } else {
                    console.error(errorCode, errorMessage);
                    setErrors({ general: 'Something bad happened, try again.' });
                }
            });
    };

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // If user is already authenticated, redirect to /marta
                navigate('/');
            } else {
                setLoading(false); // Authentication state checked, not logged in
            }
        });

        // Clean up the subscription when component unmounts
        return () => unsubscribe();
        // eslint-disable-next-line
    }, []); // Run only once when component mounts
    

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex w-full justify-stretch h-screen">
            <div className="flex flex-col w-full md:w-1/2 bg-lightgray items-center md:justify-center">
                <div className="w-full md:w-96 justify-start mt-5 p-3 align-baseline flex flex-col">
                    <div className='md:hidden mb-5'>
                        <Logo />
                    </div>
                    <h2 className="text-2xl font-semibold text-left mb-6 ">Create account</h2>
                    <form onSubmit={handleSubmit} className="flex flex-col justify-start">
                        <span className='text-slate-500 mb-1'>
                            <label htmlFor="nameInput text-sm text-lightgray text-sm">Name</label>
                        </span>
                        <input
                            className={`border-solid border-slate-300 py-1.5 rounded border-2 p-2 ${errors.name ? 'border-red-500' : ''}`}
                            type="text"
                            name="nameInput"
                            id="nameInput"
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />
                        {errors.name && <p className="text-red-500">{errors.name}</p>}
                        <span className='text-slate-500 my-2'><label htmlFor="emailInput text-sm">Email</label>
                        </span>
                        <input
                            className={`border-solid rounded py-1.5 border-2 p-2 ${errors.email ? 'border-red-500' : ''}`}
                            type="email"
                            name="emailInput"
                            id="emailInput"
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                        {errors.email && <p className="text-red-500">{errors.email}</p>}
                        <span className='text-slate-500 my-2'><label htmlFor="passwordInput text-sm">Password</label>
                        </span>
                        <input
                            className={`mb-1 rounded py-1.5 border-2 p-2 ${errors.password ? 'border-red-500' : ''}`}
                            type="password"
                            name="passwordInput"
                            id="passwordInput"
                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        />
                        {errors.password && <p className="text-red-500">{errors.password}</p>}
                        <span className="my-5">
                            <label className='flex items-center'>
                                <input
                                    type="checkbox"
                                    name="checkboxInput"
                                    id="checkboxInput"
                                    className="mr-2"
                                    checked={formData.checkboxChecked}
                                    onChange={(e) => setFormData({ ...formData, checkboxChecked: e.target.checked })}
                                />
                                <span>I have read and accept <span className='underline text-primarypink cursor-pointer'>Privacy policy</span></span>
                            </label>
                            {errors.checkboxChecked && <p className="text-red-500 mt-2">{errors.checkboxChecked}</p>}
                        </span>
                        <input className='bg-primary py-2 rounded text-lightgray text-sm cursor-pointer hover:bg-[#af42b9]' type="submit" name="submitInput" id="submitInput" value="Sign Up" />
                        <span className="mt-1.5 text-center ml-1">Already have an account?<Link to='/login'><span className='underline text-primarypink cursor-pointer'> Log in </span></Link></span>
                        {errors.general && <p className="text-red-500">{errors.general}</p>}
                        {successMessage && <p className="text-green-500">{successMessage}</p>}
                    </form>
                </div>
            </div>
            <div className={`md:flex flex-col hidden md:w-1/2 items-center justify-center bg-cover bg-no-repeat relative`} style={{ backgroundImage: `url(${doctorSign})` }}>
                <div className='absolute top-10'>
                    <Logo />
                </div>
                <div className="w-full h-full absolute" style={{ background: '#44004A99' }}></div>
                <h2 className="text-white text-3xl text-center w-[444px] font-semibold leading-10 z-10">Spend more time focusing on your patients. Let Marta take notes for you.</h2>
            </div>
        </div>
    );
}

export default Signup;
