import React from 'react';
import Model from './Model';
const Soep = () => {

  return (
    <div style={{ marginTop: "-20px" }} className="flex flex-col justify-between w-full">
      <div className="w-full md:w-[640px] lg:mt-[0px] mx-auto">
        <div className="text-sm">
          <Model />
        </div>
      </div>
    </div>
  );
};

export default Soep;
