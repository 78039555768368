import React from 'react';

const NotFoundPage = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <h1 style={{ fontSize: '4rem' }}>404 - Not Found</h1>
            <p style={{ fontSize: '1.5rem', marginTop: '20px' }}>
                The page you are looking for does not exist.
            </p>
        </div>
    );
}

export default NotFoundPage;
