import React, { useEffect, useState, useRef } from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    TypingIndicator,
    Avatar,
} from '@chatscope/chat-ui-kit-react';
import { getDoc, doc, collection, updateDoc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import { db } from '../../firebase';
import { useUser } from '../../contexts/dataUserContext';
import { useConversationContext, ConversationProvider } from '../../contexts/conversationContext';
import MartaAvatar from '../../media/martaavatar.svg';
import avatar from '../../media/memoji.png';
import arrowDown from '../../media/chevron-top-small.svg';
import Document from '../../media/document.svg';
import Spinner from '../parts/LoadingSpinner';
import { useChatContext } from '../../contexts/chatContext';
import { useChatModel } from '../../contexts/modelContext';

const ModelVisits = () => {
    const { userID, userName } = useUser();
    const location = useLocation();
    const [visitId, setVisitId] = useState(location.pathname.split('/')[2]);
    const [messages, setMessages] = useState([]);
    const { setIsTyping, processMessageToChatGPT, isTyping, notificationVisible, handleCopyToClipboard } = useConversationContext();
    const { initialInstruction } = useChatContext();
    const [isArrowDown, setIsArrowDown] = useState(true);
    const [transcript, setTranscript] = useState(null);
    const [transcriptHeight, setTranscriptHeight] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const textRef = useRef(null);
    const { model } = useChatModel();

    useEffect(() => {
        console.log("Initial instruction from context:", initialInstruction);
    }, [model]);

    const handleSend = async (message) => {
        setIsLoading(true);
        try {
            const userMessage = {
                message,
                direction: 'incoming + user',
                sender: 'user',
            };

            setMessages((prevMessages) => [...prevMessages, userMessage]);
            setIsLoading(false);
            setIsTyping(true);

            const userResponse = await processMessageToChatGPT([...messages, userMessage], model);
            const assistantMessage = userResponse.data.choices[0].message.content;

            await new Promise(resolve => setTimeout(resolve, 1000));

            const martaMessage = {
                message: assistantMessage,
                direction: 'incoming + Marta',
                sender: 'Marta',
            };

            setMessages((prevMessages) => [...prevMessages, martaMessage]);
            setIsTyping(false);

            const userRef = collection(db, `${userID}/visits/conversations`);
            const documentRef = doc(userRef, visitId);
            const visitDoc = await getDoc(documentRef);

            if (visitDoc.exists()) {
                await updateDoc(documentRef, {
                    messages: [...visitDoc.data().messages, userMessage, martaMessage],
                });
            } else {
                console.log('Visit document does not exist for ID:', visitId);
            }
        } catch (error) {
            console.error('Error handling send:', error);
        }
    };

    useEffect(() => {
        setIsArrowDown(false);
    }, []);

    useEffect(() => {
        if (isArrowDown && textRef.current) {
            setTranscriptHeight(textRef.current.clientHeight);
        }
    }, [isArrowDown, transcript]);

    useEffect(() => {
        const fetchMessagesFromFirebase = async (visitId) => {
            setIsLoading(true);
            try {
                const userRef = collection(db, `${userID}/visits/conversations`);
                const documentRef = doc(userRef, visitId);
                const visitDoc = await getDoc(documentRef);

                if (visitDoc.exists()) {
                    const messagesData = visitDoc.data().messages;
                    setMessages(messagesData);
                    const modifiedMessage = messagesData[0].message.replace(initialInstruction, '');
                    setTranscript(modifiedMessage);
                } else {
                    console.log('Visit document does not exist for ID:', visitId);
                }
            } catch (error) {
                console.error('Error fetching visit document:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchMessagesFromFirebase(visitId);
    }, [visitId, initialInstruction, userID]);

    useEffect(() => {
        setIsArrowDown(false);
        setIsTyping(false);
        setVisitId(location.pathname.split('/')[2]);
    }, [window.location.href, setIsTyping, location.pathname]);

    return (
        <div>
            {isLoading ? (
                <Spinner />
            ) : (
                <div className='h-[80vh] lg:h-[85vh] ml-1 pl-4 pr-4 lg:pl-0 lg:pr-0 w-[auto] lg:w-[640px] lg:mt-[3rem] lg:ml-[1rem] md:ml-[0rem]'>
                    <MainContainer>
                        <ChatContainer>
                            <MessageList
                                scrollBehavior="auto"
                                typingIndicator={isTyping ? <TypingIndicator content="Marta is typing..." /> : null}
                            >
                                <div className='flex flex-col mb-5'>
                                    <div className="flex-col items-center">
                                        <div className="flex">
                                            <img className="w-5 h-5" src={avatar} alt="" />
                                            <p className="text-xs ml-2 text-darkgray ">{userName}</p>
                                        </div>
                                    </div>
                                    <div className='h-10'>
                                        <p className="text-xs flex items-center mt-2 ml-5">
                                            <span>
                                                <img src={Document} alt="" />
                                            </span>
                                            <button onClick={() => setIsArrowDown((prev) => !prev)} className='flex items-center'>Transcript
                                                <span style={{ transform: isArrowDown ? 'rotate(0deg)' : 'rotate(180deg)' }}>
                                                    <img src={arrowDown} alt="" />
                                                </span>
                                            </button>

                                        </p>
                                        {isArrowDown && (
                                            <div className='p-2 leading-5 text-sm' style={{ maxWidth: '80%', marginLeft: '20px', fontSize: '1' }} ref={textRef}>
                                                {transcript ? transcript : 'Loading transcript...'}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {messages && messages.map((message, i) => (
                                    <Message key={i} model={message} className={`message${i} leading-5`} style={{ marginTop: i === 1 && isArrowDown ? transcriptHeight : '0px', fontSize: '1rem' }}>
                                        {message.sender === 'user' && (
                                            <Avatar style={{ width: '10px' }} src={require(`../../media/memoji.png`)} name="User" />
                                        )}

                                        {
                                            message.sender === "Marta" ?
                                                <Message.Header sender="Marta" /> :
                                                <Message.Header sender={userName} />
                                        }
                                        {message.sender === 'Marta' && (
                                            <Avatar
                                                style={{
                                                    padding: '4px',
                                                    width: '10px',
                                                    background: `linear-gradient(132.93deg, #EBBFFF 9.89%, #D24CDE 89.62%)`
                                                }}
                                                src={MartaAvatar}
                                                name="Assistant"
                                            />
                                        )}
                                        <div className='text-[14px]leading-relaxed'>
                                            {message.sender === 'Marta' ? (
                                                <TypingEffect text={message.message} />
                                            ) : (
                                                message.message
                                            )}
                                        </div>
                                        <Message.Footer
                                            onClick={() => handleCopyToClipboard(message.message)}
                                            sender="Copy"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </Message>

                                ))}
                            </MessageList>
                            <MessageInput placeholder="Ask follow-up questions..." onSend={handleSend} />
                        </ChatContainer>
                    </MainContainer>
                </div>
            )}
            {notificationVisible && (
                <div style={{ position: 'fixed', bottom: '10px', right: '10px', backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white', padding: '10px', borderRadius: '5px', zIndex: '50' }}>
                    Message copied to clipboard!
                </div>
            )}
        </div>
    );
};

const TypingEffect = ({ text }) => {
    // Your existing TypingEffect component
};

const ModelWithContext = () => (
    <ConversationProvider>
        <ModelVisits />
    </ConversationProvider>
);

export default ModelWithContext;