import React, { createContext, useContext, useState, useEffect } from 'react';

const ChatModelContext = createContext();

export const useChatModel = () => useContext(ChatModelContext);

export const ChatModelProvider = ({ children }) => {
    // Check for stored model value in localStorage; fallback to default if not found
    const [model, setModel] = useState(localStorage.getItem('chatModel') || 'gpt-3.5-turbo-0125');

    useEffect(() => {
        // Persist model changes to localStorage
        localStorage.setItem('chatModel', model);
    }, [model]);

    const updateModel = (newModel) => {
        setModel(newModel);
    };

    return (
        <ChatModelContext.Provider value={{ model, updateModel }}>
            {children}
        </ChatModelContext.Provider>
    );
};
