// NameThreadService.js

function removeSpacesAndCharacters(inputString) {
    if (typeof inputString !== 'string') {
        console.error('Error: Input is not a string.');
        return ''; // Return an empty string or handle non-string inputs appropriately
    }

    // Remove spaces using regular expression and replace with hyphen (-)
    let stringWithoutSpaces = inputString.replace(/\s/g, '-');
    
    // Remove characters '&' and '%'
    let stringWithoutSpecialCharacters = stringWithoutSpaces.replace(/[&%]/g, '');

    return stringWithoutSpecialCharacters;
}

export async function generateIdWithNameAndDisease(transcription) {    
    try {
        const instructions = "Summarize the following text in a maximum of 4 words without adding any other words before or after the message: [LLM Output]" + transcription;

        const response = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            },
            body: JSON.stringify({
                model: 'gpt-3.5-turbo-0125',
                messages: [{ role: "user", content: instructions}],
                max_tokens: 100,
            })
        });

        if (response.ok) {
            const data = await response.json();
            const id = data.choices[0].message.content;
            const finalID = removeSpacesAndCharacters(id);

            // Return the concatenated string
            return finalID;
        } else {
            console.error('Error calling ChatGPT API:', response.statusText);
            return ''; // Return an empty string in case of error
        }
    } catch (error) {
        console.error('Error calling ChatGPT API:', error);
        return ''; // Return an empty string in case of error
    }
}
