import React, { useRef, useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, doc, setDoc, getDocs, query, orderBy, limit } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import { db, storage } from '../../firebase';
import { useUser } from '../../contexts/dataUserContext';
import { logErrorToFirebase } from '../../utils/firebaseUtils';

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const OPENAI_API_URL = "https://api.openai.com/v1/audio/transcriptions";
const MODEL_NAME = "whisper-1";

const UploadRecording = ({ onStopButtonClick, setShowStartButton }) => {
    const inputRef = useRef();
    const [error, setError] = useState(null);
    const { userID } = useUser();
    const [loading, setIsLoading] = useState(false);
    let uniqueID; // Declare uniqueID for the mp3 visit
    uniqueID = uuidv4();

    const handleFileChange = async (event) => {
        setShowStartButton(false);
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            await uploadAudioFile(selectedFile);
        }
    };

    const uploadAudioFile = async (file) => {
        setIsLoading(true);
        try {
            const storageRef = ref(storage, `users/${userID}/visits/audios/${uniqueID}`);
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            await addDoc(collection(db, `${userID}/visits/audios`), {
                downloadURL,
                timestamp: new Date(),
            });

            const transcriptionResult = await handleTranscription();
            if (transcriptionResult) {
                await saveResultAsJson(JSON.stringify(transcriptionResult.text), uniqueID);
                document.dispatchEvent(new Event('conversationSaved'));
            } else {
                console.log("No transcription result.");
            }
        } catch (error) {
            setError("An error occurred while uploading or transcribing the audio.");
            console.error("Error uploading audio file:", error);
            await logErrorToFirebase(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    //get the last mp3 from firebase under visits/audios path and transcribe it
    const handleTranscription = async () => {
        try {
            const transcriptRef = collection(db, `${userID}/visits/audios`);
            const querySnapshot = await getDocs(query(transcriptRef, orderBy("timestamp", "desc"), limit(1)));

            if (!querySnapshot.empty) {
                const latestAudioDoc = querySnapshot.docs[0].data();
                const downloadURL = latestAudioDoc.downloadURL; // Assuming downloadURL is the field containing the URL

                // Call transcribeAudio function with the audio URL
                const transcriptionResult = await transcribeAudio(downloadURL);
                return transcriptionResult;
            } else {
                return ""; // Return an empty string indicating no transcription
            }
        } catch (error) {
            console.error("Error handling transcription:", error);
            return null; // Return null to indicate transcription error
        }
    };

    const transcribeAudio = async (audioURL) => {
        try {
            const audioBlob = await fetch(audioURL).then(response => response.blob());
            const formData = new FormData();
            formData.append("file", audioBlob);
            formData.append("model", MODEL_NAME);
            formData.append("language", "en");

            const response = await fetch(OPENAI_API_URL, {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: `Bearer ${OPENAI_API_KEY}`,
                },
            });

            return await response.json();
        } catch (error) {
            console.error("Error transcribing audio:", error);
            await logErrorToFirebase(error.toString());
            return null;
        }
    };


    // Save results in the transcripts folder
    const saveResultAsJson = async (result, uniqueID) => {
        try {
            // Save transcript under the user's collection
            const userTranscriptRef = collection(db, `${userID}/visits/transcripts`);
            const userTranscriptDocRef = doc(userTranscriptRef, uniqueID);
            await setDoc(userTranscriptDocRef, {
                conversation: result,
                timestamp: new Date(),
            });
            document.dispatchEvent(new Event('conversationSaved'));

            // Save transcript in the centralized transcripts collection
            const centralTranscriptRef = collection(db, `transcripts`);
            const centralTranscriptDocRef = doc(centralTranscriptRef, uniqueID);
            await setDoc(centralTranscriptDocRef, {
                conversation: result,
                timestamp: new Date(),
            });
            onStopButtonClick();
        } catch (error) {
            console.error("Error saving conversation to Firestore:", error.message);
        }
    };

    return (
        <>
            {loading ?
                <div className="mt-4" style={{ textAlign: "center" }}>The file is uploading...</div> : (
                    <div>
                        <input
                            type="file"
                            ref={inputRef}
                            accept=".mp3"
                            onChange={handleFileChange}
                            style={{ marginTop: "20px", display: "none" }}
                        />
                        <button
                            onClick={() => inputRef.current.click()} // Allow the user to trigger file input
                            className="text-center m-auto table cursor:pointer mt-5"
                        >
                            <span className="text-center text-black">
                            </span>
                        </button>
                        {error && <div style={{ color: "red", textAlign: "center", margin: "5px" }}>{error}</div>}
                    </div>
                )}
        </>
    );

}

export default UploadRecording;
