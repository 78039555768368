import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import doctorSign from '../../media/doctorsign.png';
import Logo from '../parts/Logo';
import Marta from '../marta/Marta';
import { Link } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        rememberMe: false,
    });
    const [errors, setErrors] = useState('');
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            setAuthenticated(!!authUser);
        });

        return () => unsubscribe();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { email, password } = formData;

        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                setNotificationVisible(true);
                navigate('/marta');
            })
            .catch((error) => {
                const errorMessage = error.message;
                console.error(errorMessage);
                setErrors('Invalid email or password. Please try again.');
            });
    };

    useEffect(() => {
        if (authenticated) {
            navigate('/marta');
        }
    }, [authenticated, navigate]);

    return (
        <>
            {authenticated ? <Marta /> :
                <div className="flex w-full justify-stretch h-screen">
                    <div className="flex flex-col w-full md:w-1/2 bg-lightgray items-center md:justify-center">
                        <div className="w-full md:w-96 justify-start mt-5 p-3 align-baseline flex flex-col">
                            <div className='md:hidden mb-5'>
                                <Logo />
                            </div>
                            <h2 className="text-2xl font-semibold text-left mb-6 ">Log in to your account</h2>
                            <form onSubmit={handleSubmit} className="flex flex-col justify-start">
                                <span className='text-slate-500 my-2'>
                                    <label htmlFor="emailInput text-sm">Email</label>
                                </span>
                                <input
                                    className="border-solid rounded py-1.5 border-2 p-2"
                                    type="email"
                                    name="email"
                                    id="emailInput"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <span className='text-slate-500 my-2'>
                                    <label htmlFor="passwordInput text-sm">Password</label>
                                </span>
                                <input
                                    className='mb-1 rounded py-1.5 border-2 p-2'
                                    type="password"
                                    name="password"
                                    id="passwordInput"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                <span className="my-5">
                                    <label className='flex items-center'>
                                        <input
                                            type="checkbox"
                                            name="rememberMe"
                                            id="rememberMe"
                                            className='mr-2'
                                            checked={formData.rememberMe}
                                            onChange={handleChange}
                                        />
                                        Remember me
                                    </label>
                                </span>
                                <input
                                    className='bg-primary py-2 rounded text-lightgray text-sm cursor-pointer'
                                    type="submit"
                                    name="submitInput"
                                    id="submitInput"
                                    value="Login"
                                />
                                <span className="mt-1.5 items-start text-start  ml-1"><Link to='/Login'><span className='underline text-primarypink cursor-pointer'>Forgot password?</span></Link></span>
                                {errors && <p className="text-red-500 mt-2">{errors}</p>}
                            </form>
                        </div>
                    </div>
                    <div className={`md:flex flex-col hidden md:w-1/2 items-center justify-center bg-cover bg-no-repeat relative`} style={{ backgroundImage: `url(${doctorSign})` }}>
                        <div className='absolute top-10'>
                            <Logo />
                        </div>

                        <div className="w-full h-full absolute mx-auto" style={{ background: '#44004A99' }}>
                        </div>
                        <h2 className="text-white text-3xl text-center w-[444px] font-semibold leading-10 z-10">Spend more time focusing on your patients. Let Marta take notes for you.</h2>
                    </div>
                    {/* Notification */}
                    {notificationVisible && (
                        <div style={{ position: 'fixed', bottom: '10px', right: '10px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', zIndex: "100", border: "solid 1px #CBD5E1" }}>
                            <h4 style={{ fontSize: "18px", fontStyle: "normal", fontWeight: "600", lineHeight: "28px" }}>Login Successful!</h4>
                            <p style={{ fontSize: "14px", color: "#64748B" }}>You have successfully logged in.</p>
                        </div>
                    )}
                </div>
            }
        </>
    );
}

export default Login;
