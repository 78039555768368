import React, { useState, useEffect } from 'react';
import { useChatContext } from '../../contexts/chatContext';
import { useChatModel } from '../../contexts/modelContext';

const EditPrompt = ({ onClose }) => {
    const { initialInstruction, updateInitialInstruction } = useChatContext();
    const [newInstruction, setNewInstruction] = useState(initialInstruction);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [promptUpdated, setPromptUpdated] = useState(false); // State to track if prompt is updated
    const { updateModel, model: currentModel } = useChatModel();
    const [tempModel, setTempModel] = useState(currentModel); // Temporary state for selected model
    const [updateType, setUpdateType] = useState(''); // New state to track the update type

    const handleUpdatePrompt = async () => {
        let updateMessage = '';
        if (tempModel !== currentModel) {
            updateModel(tempModel);
            updateMessage = 'Model updated successfully.';
            setUpdateType('model');
        }
        if (newInstruction !== initialInstruction) {
            updateInitialInstruction(newInstruction);
            updateMessage = 'Global prompt updated!';
            setUpdateType('prompt');
        }
        setNotificationVisible(true);
        setPromptUpdated(true);
        setTimeout(() => {
            setNotificationVisible(false);
            onClose();
        }, 2000);
    };

    // Effect to refresh page when prompt is updated
    useEffect(() => {
        if (promptUpdated) {
            setTimeout(() => {
                window.location.reload(); // Refresh the page
            }, 2000); // Wait for notification to disappear before refreshing
        }
    }, [promptUpdated]);

    return (
        <div onClick={onClose} className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-20 flex items-center justify-center z-50">
            <div onClick={(e) => e.stopPropagation()} className="bg-white p-4 m-2 rounded-md shadow-md flex flex-col w-[500px]">
                <h2 className="text-2xl font-semibold">Instructions</h2>
                <label className="mt-4 mb-2 text-base font-medium text-black" htmlFor="chatGptVersion">Select model</label>
                <select
                    className='mb-2 p-2 border border-gray-300 rounded w-full'
                    id="chatGptVersion"
                    name="chatGptVersion"
                    value={tempModel}
                    onChange={(e) => {
                        const newModel = e.target.value;
                        setTempModel(newModel); // Update tempModel with the selected value
                        localStorage.setItem('chatModel', newModel); // Then set localStorage with the updated value
                    }}>
                    <option value="gpt-3.5-turbo-0125">GPT-3.5 Turbo</option>
                </select>
                <p className="mt-2 mb-2 text-base font-medium text-black">Instructions</p>
                <div className="relative">
                    <textarea
                        placeholder=" "
                        className="mb-2 p-1 border border-gray-300 rounded w-full text-top h-80"
                        value={newInstruction}
                        onChange={(e) => setNewInstruction(e.target.value)}
                    />
                </div>
                <button
                    className="bg-[#C649D1] text-white px-4 py-2 rounded mt-2 mb-2"
                    onClick={handleUpdatePrompt}
                >
                    Updated
                </button>
                {notificationVisible && (
                    <div style={{ position: 'absolute', bottom: '10px', right: '10px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', zIndex: "100", border: "solid 1px #CBD5E1" }}>
                        <h4 style={{ fontSize: "18px", fontStyle: "normal", fontWeight: "600", lineHeight: "28px" }}>
                            {updateType === 'model' ? 'Model updated successfully.' : 'Global prompt updated!'}
                        </h4>
                        <p style={{ fontSize: "14px", color: "#64748B" }}>Successfully updated.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditPrompt;
