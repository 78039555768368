import Editsign from '../../media/edit-3.svg'
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../firebase';
const Edits = ({ onEditButtonClick }) => {

    const auth = getAuth();
    const navigate = useNavigate();
    // Function to push errors to the Firebase error collection
    const logErrorToFirebase = async (error) => {
        try {
            const errorCollectionRef = collection(db, 'errorLogs');
            await addDoc(errorCollectionRef, {
                timestamp: new Date(),
                error: error.toString(), // Convert error object to string for better storage
            });
        } catch (error) {
            console.error("Error logging error to Firebase:", error.message);
        }
    };
    const handleSignOut = async () => {
        try {
            await signOut(auth);
            // Redirect to the desired page after signing out (e.g., home page)
            navigate("/");
        } catch (error) {
            console.error("Error signing out:", error.message);
            await logErrorToFirebase(error);
        }
    };

    return (
        <div style={{ left: '35px', marginTop: '10px' }} className="absolute bg-white flex-col rounded-md shadow-md p-1 mt-200 w-48  z-50" >
            <span className="flex px-2 py-3 border-t-gray-200 cursor-pointer" onClick={onEditButtonClick}><img className="mr-2" src={Editsign} alt="" />Edit Prompt</span>
            <span onClick={handleSignOut} className="flex px-2 py-1 cursor-pointer"><svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <svg className="w-[12px] h-[12px] text-[rgb(52 65 86)] dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" d="M20 12H8m12 0-4 4m4-4-4-4M9 4H7a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h2" />
                </svg>
            </svg>Sign out</span>
        </div>
    )
}

export default Edits