import React from 'react';
import ModelVisits from './ModelVisits';

const Soep = () => {
  return (
    <div className='flex flex-col w-full'>
      <div className="flex flex-col justify-between">
        <div className="w-full md:w-[640px] mt-[70px] lg:mt-[0px] mx-auto">
          <div className="text-sm">
            <ModelVisits />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Soep;
