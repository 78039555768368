import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const VisitContext = createContext();

export const useVisit = () => useContext(VisitContext);

export const VisitProvider = ({ children }) => {
  const [visitText, setVisitText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userID = localStorage.getItem('userID')

  const fetchVisitName = async (visitId) => {
    setIsLoading(true);
    const visitRef = doc(db, `${userID}/visits/conversations`, visitId);
    try {
      const docSnap = await getDoc(visitRef);
      if (docSnap.exists()) {
        setVisitText(docSnap.data().name);
      } else {
        console.log("No such document!");
        setVisitText("No such visit");
      }
    } catch (error) {
      console.error("Error fetching visit name:", error);
      setVisitText("Error fetching visit");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VisitContext.Provider value={{ visitText, isLoading, fetchVisitName }}>
      {children}
    </VisitContext.Provider>
  );
};

