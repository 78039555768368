// firebaseUtils.js

import { collection, addDoc } from 'firebase/firestore'; 
import { db } from '../firebase'; 

export const logErrorToFirebase = async (error, userID) => {
    try {
        const errorCollectionRef = collection(db, 'errorLogs');
        await addDoc(errorCollectionRef, {
            timestamp: new Date(),
            user: userID,
            error: error.toString(), // Convert error object to string for better storage
        });
    } catch (error) {
        console.error("Error logging error to Firebase:", error.message);
    }
};
