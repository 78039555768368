import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import MartaPage from "../pages/Marta";
import Signup from "../components/account/Signup";
import { Helmet } from 'react-helmet';

const Home = () => {
    const [user, setUser] = useState(null);
    const [shouldReload, setShouldReload] = useState(true); // Flag to control reloading
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);

            // If user is logged in, redirect to /marta
            if (user) {
                navigate('/marta');
                // Reload the page if the flag is set to true
                if (shouldReload) {
                    setShouldReload(false); // Set the flag to false to avoid infinite reload loop
                    window.location.reload(); // Reload the page
                }
            }
        });

        // Cleanup the subscription to avoid memory leaks
        return () => unsubscribe();
        // eslint-disable-next-line 
    }, [shouldReload]); // Listen for changes in shouldReload

    return (
        <>
            <Helmet>
                <script>
                    {`
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '1225436635112560');
                        fbq('track', 'PageView');
                    `}
                </script>
                <noscript>
                    {`
                        <img height="1" width="1" style="display:none"
                        src="https://www.facebook.com/tr?id=1225436635112560&ev=PageView&noscript=1" />
                    `}
                </noscript>
            </Helmet>
            {user ? <MartaPage /> : <Signup />}
        </>
    );
}

export default Home;
