// PostHogContext.js
import React, { createContext } from 'react';
import posthog from 'posthog-js';

posthog.init('phc_cpKzCK2k5ciH3VsIvfnsExuwyTQC7UfR62GpXJNAO39', { api_host: 'https://eu.i.posthog.com' });

const PostHogContext = createContext(posthog);

export const PostHogProvider = ({ children }) => (
  <PostHogContext.Provider value={posthog}>
    {children}
  </PostHogContext.Provider>
);

export default PostHogContext;
