import React, { useState, useEffect } from 'react';

const TitleModel = () => {
    const [lastUpdatedVisit, setLastUpdatedVisit] = useState(null);

    useEffect(() => {
        // Retrieve finalID from localStorage
        const finalIDLocal = localStorage.getItem('finalID');

        // Set finalID to lastUpdatedVisit from localStorage, if available
        if (finalIDLocal !== null) {
            setLastUpdatedVisit(finalIDLocal);
        } else {
            // If no value found in localStorage, retrieve from sessionStorage
            const finalIDSession = sessionStorage.getItem('finalID');
            setLastUpdatedVisit(finalIDSession);
        }

        // Clean up function
        return () => {
            // Clear finalID from sessionStorage if needed
            sessionStorage.removeItem('finalID');
            // Clear finalID from localStorage if needed
            localStorage.removeItem('finalID');
        };
    }, []); // Run effect only once when component mounts

    useEffect(() => {
        // Function to update lastUpdatedVisit when a new item is added to sessionStorage
        const handleStorageChange = () => {
            const newFinalID = sessionStorage.getItem('finalID');
            setLastUpdatedVisit(newFinalID);
        };

        // Add event listener for changes in sessionStorage
        window.addEventListener('storage', handleStorageChange);

        // Clean up function to remove event listener
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []); // Run effect only once when component mounts

    return (
        <>
            {lastUpdatedVisit && (
              <></>
            )}
        </>
    );
};

export default TitleModel;
