import React, { useState } from 'react';
import { Modal, Label, TextInput } from "flowbite-react";
import 'flowbite/dist/flowbite.css';
import { useParams } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useUser } from '../../contexts/dataUserContext';
import { logErrorToFirebase } from '../../utils/firebaseUtils';
import './../style/sidebar.css';

const ModalRename = ({ openRename, setOpenRename }) => {
    const { visitId } = useParams();
    const { userID } = useUser();
    const [newVisitName, setNewVisitName] = useState(""); // State to hold the new visit name
    const [notificationVisible, setNotificationVisible] = useState(false);

    const handleSave = () => {
        setOpenRename(false);
        renameVisit(visitId, newVisitName);
        setNotificationVisible(true);
        setTimeout(() => {
            setNotificationVisible(false);
        }, 2000);
        window.location.reload(); // Refresh the page
    };

    const renameVisit = async (visitId, newName) => {
        const visitRef = doc(db, `${userID}/visits/conversations`, visitId);
        try {
            await updateDoc(visitRef, { name: newName });
        } catch (error) {
            console.error("Error updating visit name:", error);
            await logErrorToFirebase(error);
        }
    };

    return (
        <>
            <div className="relative">
                <Modal className="bg-[#0000004D] flex justify-center items-center pt-[30vh] pb-[30vh] z-[10000000000]" show={openRename} size="md" onClose={() => setOpenRename(false)} popup>
                    <Modal.Header />
                    <Modal.Body>
                        <div style={{ "marginTop": '-50px' }} >
                            <h2 className="text-xl font-semibold mt-4 mb-4 ">Edit visit name</h2>
                            <Label className='mt-6 mb-4'>Visit name</Label>
                            <TextInput
                               className="mt-2 rounded-md custom-text-input"
                                id="email"
                                placeholder={visitId ? visitId.replace(/-/g, ' ') : "Enter visit name"}
                                required={true}
                                onChange={(e) => setNewVisitName(e.target.value)}
                            />
                            <button onClick={handleSave} className="bg-[#C649D1] w-full text-white px-4 py-2 rounded-md mt-4 mb-2">
                                Save
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {notificationVisible && (
                <div style={{ position: 'fixed', bottom: '10px', right: '10px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', zIndex: "100", border: "solid 1px #CBD5E1" }}>
                    <h4 style={{ fontSize: "18px", fontStyle: "normal", fontWeight: "600", lineHeight: "28px" }}>
                        Visit renamed successfully!
                    </h4>
                    <p style={{ fontSize: "14px", color: "#64748B" }}>Successfully updated.</p>
                </div>
            )}
        </>
    )
}

export default ModalRename;
