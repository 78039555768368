import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, addDoc, getDoc, doc, setDoc, serverTimestamp  } from 'firebase/firestore';
import styled, { keyframes } from 'styled-components';

const UserContext = createContext();

// Animation for the loading circle
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to { 

     
    transform: rotate(360deg);
  }
`;

// Styled component for the loading circle
const LoadingCircle = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid transparent;
  border-top: 4px solid ${({ color }) => color || 'blue'}; // Custom color, default is blue
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${rotate} 1s linear infinite; // Animation duration and timing
`;

export const UserProvider = ({ children }) => {
    const auth = getAuth();
    const [userData, setUserData] = useState({
        userName: 'Loading...',
        userID: 'Loading...',
        email: 'Loading...',
        photoUrl: 'Loading...'
    });
    const [loading, setLoading] = useState(true); // Add loading state
    const storedName = localStorage.getItem('userName');

    // Function to add user to Firestore collection if not exists
    const addUserToDatabase = async (userID) => {
        try {
            const db = getFirestore();
            const userDocRef = doc(db, 'users-zapier', userID);

            const userDoc = await getDoc(userDocRef);
            if (!userDoc.exists()) {
                // Add a document to the 'users-zapier' collection with the user ID as the document ID
                await setDoc(userDocRef, {
                    userID: userID,
                    userName: auth.currentUser.displayName || storedName,
                    email: auth.currentUser.email,
                    dateCreated: serverTimestamp() 
                });
            }
        } catch (error) {
            console.error('Error adding user to database:', error);
        }
    };

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in
                const loggedInUserData = {
                    userName: auth.currentUser.displayName || storedName,
                    data: auth.currentUser,
                    userID: user.uid,
                    email: user.email,
                    photoUrl: user.photoURL
                };
                // Add user data to database
                addUserToDatabase(user.uid);
                // Set user data state
                setUserData(loggedInUserData);
                localStorage.setItem('userID', loggedInUserData.userID);
            } else {
                // User is signed out, reset userData
                setUserData({
                    userName: 'No name',
                    userID: 'No ID',
                    email: 'No email',
                    photoUrl: 'No photo'
                });
            }
            setLoading(false);
        });
        // eslint-disable-next-line 
    }, [auth]);

    if (loading) {
        return <LoadingCircle color="#c749d1" />;
    }

    return (
        <UserContext.Provider value={userData}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext);
};
