import { getFirestore, doc, getDoc } from 'firebase/firestore';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // in milliseconds

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const getUserSignUpDate = async (userId) => {
    const db = getFirestore();
    const userDocRef = doc(db, 'users-zapier', userId);
    
    for (let attempt = 1; attempt <= MAX_RETRIES; attempt++) {
        try {
            const userDoc = await getDoc(userDocRef);
            
            if (userDoc.exists()) {
                const signUpTimestamp = userDoc.data().dateCreated.toDate();
                const currentDate = new Date();
                const sevenDaysLater = new Date(signUpTimestamp.getTime() + 7 * 24 * 60 * 60 * 1000);
                const daysRemaining = Math.ceil((sevenDaysLater - currentDate) / (24 * 60 * 60 * 1000));
                
                // Return the number of days remaining
                return daysRemaining;
            } else {
                if (attempt < MAX_RETRIES) {
                    await delay(RETRY_DELAY);
                } else {
                    throw new Error('User document does not exist after multiple attempts');
                }
            }
        } catch (error) {
            if (attempt < MAX_RETRIES) {
                await delay(RETRY_DELAY);
            } else {
                console.error('Error fetching user sign-up date after multiple attempts:', error);
                throw error;
            }
        }
    }
};
