import Conversation from "./Conversation";
import SidebarApp from './SidebarApp';
import { ChatProvider } from '../../contexts/chatContext';
import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Layout from '../layout/Layout';
import { ConversationProvider } from '../../contexts/conversationContext';
import { ChatModelProvider } from '../../contexts/modelContext';
import { VisitProvider } from '../../contexts/visitContext';
import TrialExpired from "../parts/TrialExpired";
import { useUser } from '../../contexts/dataUserContext';
import { getUserSignUpDate } from "../../services/firebaseService";

const Marta = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [remainingDays, setDaysRemaining] = useState(7);
    const { userID } = useUser();

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            setUser(authUser);
            // If user is not logged in, navigate them to the login page
            if (!authUser) {
                navigate('/login');
            }
        });

        // Cleanup the subscription to avoid memory leaks
        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        const fetchDays = async () => {
            const remainingDays = await getUserSignUpDate(userID);
            setDaysRemaining(remainingDays);
        };
        fetchDays();
    }, [])


    if (!user) {
        return null;
    }

    return (
        <ConversationProvider>
            <ChatProvider>
                <ChatModelProvider>
                    <VisitProvider>
                        <Layout>
                            <div className="flex w-screen overflow-hidden">
                                <div className="hidden lg:flex">
                                    <SidebarApp />
                                </div>

                                <Conversation />
                            </div>
                            {remainingDays <= 0 ? (
                                <TrialExpired />
                            ) : (
                                <div>
                                </div>
                            )}
                        </Layout>
                    </VisitProvider>
                </ChatModelProvider>
            </ChatProvider>
        </ConversationProvider>


    );
}

export default Marta;
