import React, { useState, useEffect, useRef } from 'react';
import Profile from '../parts/Profile';
import Edits from '../parts/Edits';
import Marta from '../../media/Union.svg';
import plus from '../../media/plus-circle.svg';
import EditPrompt from '../parts/EditPrompt';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/dataUserContext';
import '../style/sidebar.css';
import { logErrorToFirebase } from '../../utils/firebaseUtils';
import ModalAreYouSure from '../flowbiteEdits/ModalAreYouSure';
import ModalRename from '../flowbiteEdits/ModalRename';
import VisitSegment from '../parts/visitSegment';
import Upgrade from '../parts/Upgrade';
import { getUserSignUpDate } from '../../services/firebaseService';

const SidebarApp = ({ isOpen }) => {
    const [showEdits, setShowEdits] = useState(false);
    const [showEditPrompt, setShowEditPrompt] = useState(false);
    const [visits, setVisits] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openRename, setOpenRename] = useState(false);
    const { visitId } = useParams();
    const { userID } = useUser();
    const location = useLocation();
    const [visibleModalId, setVisibleModalId] = useState(null);
    const scrollableParentRef = useRef(null);
    const [daysRemaining, setDaysRemaining] = useState(null); // State to store the days remaining

    const handleProfileHover = () => {
        setShowEdits(true);
    };

    const handleProfileLeave = () => {
        setShowEdits(false);
    };

    const handleEditButtonClick = () => {
        setShowEditPrompt(true);
    };

    const handleCloseEditPrompt = () => {
        setShowEditPrompt(false);
    };

    const handleRefresh = () => {
        if (location.pathname === '/marta') {
            window.location.reload();
        }
    };
    const handleMouseEnter = (id) => {
        setVisibleModalId(id);
    };

    const handleMouseLeave = () => {
        setVisibleModalId(null);
    };

    useEffect(() => {
        let unsubscribe = () => { }; // Initialize unsubscribe function

        const fetchVisits = async () => {
            const remainingDays = await getUserSignUpDate(userID);
            setDaysRemaining(remainingDays);

            if (auth.currentUser) {
                try {
                    const userVisitsRef = collection(db, `${userID}/visits/conversations`);
                    const visitsQuery = query(userVisitsRef);

                    unsubscribe = onSnapshot(visitsQuery, (snapshot) => {
                        const visitsData = snapshot.docs.map(doc => ({
                            id: doc.id,
                            name: doc.data().name,
                            timestamp: doc.data().timestamp,
                        }));

                        // Sort visits by timestamp in descending order
                        const sortedVisits = visitsData.sort((a, b) => b.timestamp - a.timestamp);

                        setVisits(sortedVisits);
                    });
                } catch (error) {
                    console.error("Error fetching visits:", error);
                    await logErrorToFirebase(error);
                }
            }
        };

        fetchVisits();

        // Return the unsubscribe function to clean up the listener
        return () => {
            unsubscribe();
        };
    }, [visitId]);

    // Function to categorize visits into different time ranges
    const categorizeVisits = (visits) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

        const todayVisits = [];
        const yesterdayVisits = [];
        const sevenDaysAgoVisits = [];
        const otherVisits = [];

        visits.forEach(visit => {
            if (visit.timestamp && visit.timestamp.toDate) {
                const visitDate = new Date(visit.timestamp.toDate());
                if (visitDate.toDateString() === today.toDateString()) {
                    todayVisits.push({
                        ...visit,
                        id: visit.id.replace(/-/g, ' ') // Replace "-" with a space in the ID
                    });
                } else if (visitDate.toDateString() === yesterday.toDateString()) {
                    yesterdayVisits.push({
                        ...visit,
                        id: visit.id.replace(/-/g, ' ') // Replace "-" with a space in the ID
                    });
                } else if (visitDate >= sevenDaysAgo) {
                    sevenDaysAgoVisits.push({
                        ...visit,
                        id: visit.id.replace(/-/g, ' ') // Replace "-" with a space in the ID
                    });
                } else {
                    otherVisits.push({
                        ...visit,
                        id: visit.id.replace(/-/g, ' ') // Replace "-" with a space in the ID
                    });
                }
            }
        });
        return { todayVisits, yesterdayVisits, sevenDaysAgoVisits, otherVisits };
    };

    const { todayVisits, yesterdayVisits, sevenDaysAgoVisits, otherVisits } = categorizeVisits(visits);

    return (
        <>
            <ModalAreYouSure visits={visits} openModal={openModal} setOpenModal={setOpenModal} />
            <ModalRename openRename={openRename} setOpenRename={setOpenRename} />

            <section className={`bg-lightgray w-60 h-screen border-r-2 ${isOpen ? 'md:flex' : 'hidden md:flex'}`}>
                <div className='p-3 w-60 sidebar'>
                    <div className='flex justify-between'>
                        <Link to={`/marta`}>
                            <span className="flex items-center">
                                <img className="mr-2 w-5 h-5 background-none" src={Marta} alt="Marta Logo" />
                                <p className="text-black text-1xl text-center font-semibold align-middle">Marta</p>
                            </span>
                        </Link>
                        <span onClick={handleProfileHover}><Profile /></span>
                    </div>

                    <div className='h-[50px]'>
                        {showEdits && <span onMouseLeave={handleProfileLeave}><Edits onEditButtonClick={handleEditButtonClick} /></span>}
                    </div>
                    <div className="flex content-center items-center justify-items-center">
                        <img className="w-4 h-4 mr-2" src={plus} alt="" />
                        <Link to={`/marta`} onClick={handleRefresh}>
                            <button >New visit</button>
                        </Link>
                    </div>
                    <div ref={scrollableParentRef} className="mt-5 h-[75vh] overflow-y-scroll sidebar" >
                        <>
                            <VisitSegment
                                title="Today"
                                visits={todayVisits}
                                handleMouseEnter={handleMouseEnter}
                                handleMouseLeave={handleMouseLeave}
                                visibleModalId={visibleModalId}
                                setOpenRename={setOpenRename}
                                setOpenModal={setOpenModal}
                                visitId={visitId}
                            />
                            <VisitSegment
                                title="Yesterday"
                                visits={yesterdayVisits}
                                handleMouseEnter={handleMouseEnter}
                                handleMouseLeave={handleMouseLeave}
                                visibleModalId={visibleModalId}
                                setOpenRename={setOpenRename}
                                setOpenModal={setOpenModal}
                                visitId={visitId}
                            />
                            <VisitSegment
                                title="7 Days Ago"
                                visits={sevenDaysAgoVisits}
                                handleMouseEnter={handleMouseEnter}
                                handleMouseLeave={handleMouseLeave}
                                visibleModalId={visibleModalId}
                                setOpenRename={setOpenRename}
                                setOpenModal={setOpenModal}
                                visitId={visitId}
                            />
                            <VisitSegment
                                title="Previous 30 Days"
                                visits={otherVisits}
                                handleMouseEnter={handleMouseEnter}
                                handleMouseLeave={handleMouseLeave}
                                visibleModalId={visibleModalId}
                                setOpenRename={setOpenRename}
                                setOpenModal={setOpenModal}
                                visitId={visitId}
                            />
                        </>
                        <div className='absolute bottom-0 md:mb-5 mb-[50px]  pt-2 w-215 bg-[#f9fafb] w-[217px]'>
                            <Upgrade daysRemaining={daysRemaining} />
                        </div>
                    </div>
                </div>
                {showEditPrompt && <EditPrompt onClose={handleCloseEditPrompt} />}
            </section >
        </>
    );
};

export default SidebarApp;
